export const environment = {
  production: true,
  RECAPTCHA_V3_SITE_KEY: '6LcQG5IhAAAAAGBXEYKwSWAFy6Obcu44qYRm2t1l',
  razorPayID: 'rzp_live_Qqeiv1CcP4jhYo',
  stripeID: 'pk_live_51LNW5LJ17IHshykoEiA78lnhR8BrDbvpX1sV2msUjPZ0kvN6mrl6mzQgSFL9pjdcBB9AvM5CSbuVrLDvOCUH29It003KoPtoTe',
  dialog360ID: '805HUxPA',
  firebase: {
    apiKey: "AIzaSyB76kOK6DIoik5N8H6W2SzPMB95YwHObpk",
    authDomain: "sobot-d9428.firebaseapp.com",
    projectId: "sobot-d9428",
    storageBucket: "sobot-d9428.appspot.com",
    messagingSenderId: "878353671320",
    appId: "1:878353671320:web:d9fbc24327110d7b441ced",
    measurementId: "G-MCYGWM2295",
  },
  FCM_VapidKey: "BEqZwDpYTjJrra8-s20DD1jTvVj2wfX21xU66AJ6iITZ1R0fZCArycCn3TCxU3GCGWOim-esCCvOVgT34gpw6qM",
  posthog: 'phc_4LfrPzfMnt0MEZPZArHSajdLCYlvCSWGg76o9wnTOPC',
  configuration: "Sobot",
  partner: 'sobot',
  API_URL: 'api.sobot.in',
  UI_BASE_URL: 'app.sobot.in',
  favicon: './assets/sobot/logo.png',
  https: true
};
